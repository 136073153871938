import React from "react"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import SignIn from '../components/accounts/SignIn'


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <SignIn />
  </Layout>
)

export default IndexPage
