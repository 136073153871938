import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { isLoggedIn, logout, getUser } from './../../services/auth'

const Header = ({ siteTitle }) => {
  const content = { message: "", login: true }
  if (isLoggedIn()) {
    content.message = `Hello, ${getUser().name}`
  } else {
    content.message = "You are not logged in"
  }
  return (
    <header
      style={{
        background: `rebeccapurple`,
        marginBottom: `1.45rem`,
        alignItems: 'center',
        display: 'flex',
        minHeight: 50
      }}
    >
      <div
        style={{
          display: "flex",
          flex: "1",
          justifyContent: "space-between",
          color: 'white'
        }}
      >
        <span>{content.message}</span>
        <nav>
          <Link to="/" style={{ color: 'white' }}>Home</Link>
          {` `}
          <Link to="/app/profile" style={{ color: 'white' }}>Profile</Link>
          {` `}
          {isLoggedIn() ? (
          <a
            href="/"
            onClick={event => {
              event.preventDefault()
              logout(() => navigate(`/app/login`))
            }}
          >
            Logout
          </a>
        ) : null}
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
